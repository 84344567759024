<template>
  <div>
    <!-- Table -->
    <b-table
      id="periodosTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getResourceList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <!-- Nombre -->
      <template #cell(descriptor)="row">
        <span :class="{ 'text-muted': row.item.is_deleted }">{{ row.item.descriptor }}</span>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <u-d-controls
            :id="row.item.id"
            editPath="periodoModule/getResourceToEdit"
            deletePath="periodoModule/deleteResource"
            :editPermissions="['actualizar periodos']"
            :deletePermissions="['eliminar periodos']"
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>
    </b-table>
    <loading v-else></loading>
    <!--div v-if="isThereData">
      <paginator
        aria-controls="usersTable"
        :disabled="!getAvailableList"
        show-path="userModule/getUsers"
        :resource="getResourceList"
      ></paginator>
    </div-->
    <!-- End Table -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const STORE_MODULE = 'periodoModule'

export default {
  name: 'PeriodoList',

  created () {
    this.submit()
  },

  data () {
    return {
      fields: [
        { key: 'descriptor', label: 'Descriptor', sortable: true },
        { key: 'acciones', label: 'Acciones' }
      ]
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceList',
      'getAvailableList',
      'isThereData',
      'getResource'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${STORE_MODULE}/getResource`)

      if (error) this.$notify({ error, message }, 'Periodos')
    },

    getRoleVariant (role) {
      if (role === 'admin') return 'success'

      return 'primary'
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    }
  }
}
</script>

<style scoped>

</style>

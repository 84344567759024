<template>
  <base-view title="Periodos" icon="calendar">
    <div>
      <check-authorization :requiresAuthorizations="['indice periodos']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Periodos

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Form -->
              <check-authorization
                :requiresAuthorizations="['crear periodos']"
                :overrideIf="$store.getters['periodoModule/isEditingResource']"
              >
                <periodo-form
                  :value="showForm || isEditingResource"
                  @input="showForm = $event"
                />
              </check-authorization>
              <!-- Search -->
              <!-- <periodo-search></periodo-search> -->
              <!-- Options -->
              <!-- <div class="dropdown no-caret">
                <button
                  class="btn btn-transparent-dark btn-icon dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                  <a class="dropdown-item my-cursor" @click="getUsers(null)">
                    Recargar
                  </a>
                </div>
              </div> -->
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <periodo-list @on-got-item="showForm = true"></periodo-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import PeriodoForm from '@/components/Administracion/Periodos/PeriodoForm'
import PeriodoList from '@/components/Administracion/Periodos/PeriodoList'
// import PeriodoSearch from '@/components/Administracion/Periodos/PeriodoSearch'
import { mapActions, mapGetters } from 'vuex'

const STORE_MODULE = 'periodoModule'

export default {
  name: 'PeriodosMainView',

  components: {
    PeriodoForm,
    PeriodoList
  },

  data () {
    return {
      hideButton: false,
      showForm: false
    }
  },

  computed: mapGetters(STORE_MODULE, ['isEditingResource']),

  methods: {
    ...mapActions(STORE_MODULE, ['getResources'])
  }
}
</script>

<style scoped>

</style>
